import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Send, RotateCcw, ArrowUp } from 'lucide-react';

const API_BASE_URL = process.env.REACT_APP_API_URL;

const ChatInterface = () => {
  const [messages, setMessages] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [userInput, setUserInput] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isEnded, setIsEnded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [debugData, setDebugData] = useState(null);
  const [conversationEnded, setConversationEnded] = useState(false);
  const messagesEndRef = useRef(null);
  const userInputRef = useRef(null);
  const hasFetchedInitialQuestion = useRef(false);
  const [userId, setUserId] = useState('User123'); // Initial user ID

  const shouldBeHalfWidth = useCallback((optionText) => {
    return optionText.length < 25;
  }, []);

  const handleSendAnswer = useCallback(async (event) => {
    if (event?.key === 'Enter' && event?.shiftKey) {
      return;
    }
    if (event?.key === 'Enter' && !event?.shiftKey) {
      event.preventDefault();
    }
    if ((!event || event.key === 'Enter') && (userInput || selectedOptions.length > 0)) {
      if (!currentQuestion) return;
      let answer;
      if (currentQuestion.multiple_choice?.length > 0) {
        answer = currentQuestion.multi_select === "Ja" ? selectedOptions.join(', ') : selectedOptions[0];
      } else {
        answer = userInput;
      }
  
      setMessages(prevMessages => [
        ...prevMessages,
        { type: 'user', content: answer }
      ]);
  
      setUserInput('');
      setIsLoading(true);
  
      const updatedQuestion = { ...currentQuestion, answer };
      try {
        const response = await fetch(`${API_BASE_URL}/next_question`, {
          method: 'POST',
          headers: {
            'Authorization': process.env.REACT_APP_API_AUTH,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(updatedQuestion),
        });
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        const data = await response.json();
        
        // Update userId with the new value from the response
        if (data.user_id) {
          setUserId(data.user_id);
        }

        setMessages(prevMessages => [
          ...prevMessages,
          ...(Array.isArray(data.debug) ? data.debug.map(debugMsg => ({ type: 'debug', content: debugMsg })) : []),
          { type: 'app', content: data.question_asked }
        ]);
        setCurrentQuestion(data);
        setSelectedOptions([]);
        if (data.end) {
          setIsEnded(true);
        }
        if (data.question_asked === 'Dat waren mijn vragen, dankjewel!' && typeof data.debug === 'object') {
          setDebugData(data.debug);
          setConversationEnded(true);
        }
      } catch (error) {
        console.error('Kut het gaat fout:', error);
        setMessages(prevMessages => [...prevMessages,
          { type: 'app', content: "Kut het gaat fout" }
        ]);
      } finally {
        setIsLoading(false);
      }
    }
  }, [currentQuestion, selectedOptions, userInput]);

  const handleOptionChange = useCallback((option) => {
    setSelectedOptions(prevOptions =>
      currentQuestion?.multi_select === "Ja"
        ? prevOptions.includes(option)
          ? prevOptions.filter(item => item !== option)
          : [...prevOptions, option]
        : [option]
    );
  }, [currentQuestion]);

  const fetchQuestion = useCallback(async () => {
    if (hasFetchedInitialQuestion.current) return;
    setIsLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/start_session`, {
        method: 'POST',
        headers: {
          'Authorization': process.env.REACT_APP_API_AUTH,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      
      // Update userId with the new value from the response
      if (data.user_id) {
        setUserId(data.user_id);
      }

      setCurrentQuestion(data);
      setMessages(prevMessages => {
        if (!prevMessages.some(msg => msg.content === data.question_asked)) {
          return [...prevMessages, { type: 'app', content: data.question_asked }];
        }
        return prevMessages;
      });
      hasFetchedInitialQuestion.current = true;
    } catch (error) {
      console.error('Error fetching initial question:', error.message);
      setMessages(prevMessages => {
        if (!prevMessages.some(msg => msg.content === "Error fetching question. Please try again.")) {
          return [...prevMessages, { type: 'app', content: "Error fetching question. Please try again." }];
        }
        return prevMessages;
      });
    } finally {
      setIsLoading(false);
    }
  }, []);

  const resetConversation = useCallback(() => {
    setMessages([]);
    setCurrentQuestion(null);
    setUserInput('');
    setSelectedOptions([]);
    setIsEnded(false);
    setConversationEnded(false);
    hasFetchedInitialQuestion.current = false;
    fetchQuestion();
  }, [fetchQuestion]);

  const renderOptions = useMemo(() => {
    if (!currentQuestion?.multiple_choice) return [];

    const fullWidthOptions = [];
    const halfWidthOptions = [];

    currentQuestion.multiple_choice.forEach((option) => {
      if (shouldBeHalfWidth(option)) {
        halfWidthOptions.push(option);
      } else {
        fullWidthOptions.push(option);
      }
    });

    return [...fullWidthOptions, ...halfWidthOptions].map((option, index) => (
      <div
        key={index}
        className={`px-1 mb-2 ${shouldBeHalfWidth(option) ? 'w-1/2' : 'w-full'}`}
      >
        <label
          className={`
            flex items-center space-x-2 bg-white p-2 rounded w-full cursor-pointer
            ${selectedOptions.includes(option) ? 'ring-2 ring-blue-500' : 'border border-black hover:bg-gray-50'}
            transition-all duration-200 ease-in-out
          `}
        >
          <input
            type={currentQuestion.multi_select === "Ja" ? "checkbox" : "radio"}
            checked={selectedOptions.includes(option)}
            onChange={() => handleOptionChange(option)}
            className="form-checkbox h-5 w-5 text-blue-600"
            name="options"
          />
          <span className="flex-1 text-sm">{option}</span>
        </label>
      </div>
    ));
  }, [currentQuestion, selectedOptions, shouldBeHalfWidth, handleOptionChange]);

  useEffect(() => {
    if (!hasFetchedInitialQuestion.current) {
      console.log("Fetching initial question...");
      fetchQuestion();
      hasFetchedInitialQuestion.current = true;
    }
  }, [fetchQuestion]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  useEffect(() => {
    if (currentQuestion && !currentQuestion.multiple_choice?.length) {
      userInputRef.current?.focus();
    }
  }, [currentQuestion]);

  return (
    <div className="flex flex-col h-screen max-w-md mx-auto p-4 bg-gray-100">
      <div className="w-full bg-white rounded-lg shadow p-4 mb-2 text-center">
        <h1 className="text-2xl font-bold">Didactia</h1>
        <p className="text-sm text-gray-600">{userId}</p> {/* Display user ID */}
      </div>
      <div className="flex-grow overflow-y-auto mb-4 bg-white rounded-lg shadow p-4">
        {messages.map((message, index) => (
          <div key={index} className={`mb-2 ${message.type === 'user' ? 'text-right' : message.type === 'debug' ? 'text-center' : 'text-left'}`}>
            <span className={`inline-block rounded-lg p-2 ${
              message.type === 'user' ? 'bg-blue-500 text-white' :
              message.type === 'debug' ? 'bg-green-200 text-green-800' :
              'bg-gray-200'
            }`}>
              {message.content}
            </span>
          </div>
        ))}
        {isLoading && <div className="text-center">Aan het denken...</div>}
        <div ref={messagesEndRef} />
      </div>

      {!isEnded && !conversationEnded && (
        <div className="space-y-2">
          {currentQuestion && currentQuestion.multiple_choice && currentQuestion.multiple_choice.length > 0 ? (
            <div className="space-y-2">
              {currentQuestion.multi_select === "Ja" && (
                <div className="italic text-gray-600">Meerdere antwoorden mogelijk</div>
              )}
              <div className="flex flex-wrap -mx-1">
                {renderOptions}
              </div>
            </div>
          ) : (
            <input
              ref={userInputRef}
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              onKeyPress={handleSendAnswer}
              placeholder="Type your answer..."
              className="w-full p-2 border rounded"
            />
          )}
          <div className="flex justify-between">
            <button onClick={resetConversation} className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300 transition">
              <RotateCcw className="w-4 h-4 mr-2 inline" />
              Opnieuw
            </button>
            <button 
              onClick={() => handleSendAnswer()}
              disabled={isLoading || (!userInput && selectedOptions.length === 0)}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {currentQuestion && currentQuestion.multiple_choice && currentQuestion.multiple_choice.length > 0 ? (
                <ArrowUp className="w-4 h-4 mr-2 inline" />
              ) : (
                <Send className="w-4 h-4 mr-2 inline" />
              )}
              Verstuur
            </button>
          </div>
        </div>
      )}

      {debugData && (
        <div className="overflow-x-auto bg-white rounded-lg shadow p-4 mt-4">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Number</th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Question</th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Answer</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {Object.entries(debugData).map(([number, qaObject], index) => {
                const [question, answer] = Object.entries(qaObject)[0];
                return (
                  <tr key={index}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{number}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{question}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{answer}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

const PasswordScreen = ({ onPasswordSubmit }) => {
  const [password, setPassword] = useState('');
  const passwordInputRef = useRef(null);

  useEffect(() => {
    passwordInputRef.current?.focus();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (password === 'didactia') {
      onPasswordSubmit();
    } else {
      alert('Incorrect password');
    }
  };

  return (
    <div className="flex flex-col h-screen justify-center items-center bg-gray-100">
      <form onSubmit={handleSubmit} className="bg-white p-6 rounded shadow-md">
        <h2 className="text-xl font-bold mb-4">Enter Password</h2>
        <input
          ref={passwordInputRef} // Add ref to the input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          className="w-full p-2 border rounded mb-4"
        />
        <button type="submit" className="w-full bg-blue-500 text-white p-2 rounded">
          Submit
        </button>
      </form>
    </div>
  );
};

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handlePasswordSubmit = () => {
    setIsAuthenticated(true);
  };

  return (
    <div className="App bg-gray-100 min-h-screen">
      {isAuthenticated ? <ChatInterface /> : <PasswordScreen onPasswordSubmit={handlePasswordSubmit} />}
    </div>
  );
};

// Add this line to export App as the default export
export default App;